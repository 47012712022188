<template>
  <table-view
    ref="tableView"
    url="/dispatch/taskPatrol/listForPage"
    delete-url="/dispatch/taskPatrol/delete"
    :batch-removable="isAuthed('dispatch_patrol_delete')"
    :filterable="false"
    :filter-form="dataForm"
    :headers="headers"
    :split-index="2"
    selectable
    :viewable="true"
    show-index
    single
  >
    <el-form slot="search" @keyup.enter.native="query">
      <el-input v-model="dataForm.siteName" placeholder="工地名称"></el-input>
      <!-- <el-input v-model="dataForm.baseName" placeholder="所属基地"></el-input> -->
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'dispatch-patrol',

  components: { TableView },

  data () {
    return {
      headers: [
        { id: 1, prop: 'createTime', label: '巡查时间', width: 140 },
        {
          id: 2,
          prop: 'patrolType',
          label: '巡查类型',
          width: 100,
          formatter: (row, column, cellValue) => {
            // '勘察状态：1未勘察 2已勘察
            switch (cellValue) {
              case 1:
                return '人工巡查'
              case 2:
                return '线上巡查'
              default:
                return '未知'
            }
          }
        },
        { id: 3, prop: 'patrolUserName', label: '巡查人员', width: 120 },
        { id: 4, prop: 'number', label: '施工车辆', width: 100 },
        { id: 5, prop: 'carNickName', label: '车长', width: 100 },
        { id: 6, prop: 'siteName', label: '工地名称', width: 100 },
        { id: 7, prop: 'constructionPart', label: '施工部位', width: 150 },
        { id: 8, prop: 'siteAddress', label: '工地地址', width: 250 },
        { id: 9, prop: 'content', label: '巡查结果', width: 200 },
        {
          id: 10,
          prop: 'status',
          label: '巡查状态',
          width: 100,
          formatter: (row, column, cellValue) => {
            // '勘察状态：1未勘察 2已勘察
            switch (cellValue) {
              case 1:
                return '待巡查'
              case 2:
                return '巡查通过'
              case 3:
                return '巡查发现问题'
              case 4:
                return '问题整改完成'
              case 5:
                return '问题确认完成'
              default:
                return '未知'
            }
          }
        }
      ],
      dataForm: {
        baseName: '',
        siteName: '',
        dispatchTime: ''
      },
      loading: false
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    },

    async send () {
      // const selection = this.$refs.tableView.$refs.table.selection
      // if (!selection.length) return this.$opts.alert('请先选择记录')
      this.loading = true
      try {
        const data = await this.$http({
          url: this.$http.adornUrl('/dispatch/taskPatrol/listForPage'),
          method: 'post'
          // data: selection.map(s => s.id)
        })
        this.loading = false
        if (data.code === 200) {
          this.$opts.success()
          this.query()
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    }
  }
}
</script>
